// ////////// make action /////////
// const COMPANY="COMPANY";
// const companyAction=()=>{
// return {
//   type,
// }

// }
// //////////////////////////////

const defaultCompany = () => {
  const company = localStorage.getItem("defaultCompany")
    ? JSON.parse(localStorage.getItem("defaultCompany"))
    : false;
  return company;
};
///////////// reducer //////////////
const initState = {
  selectedCompany: defaultCompany() || {},
};

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case "CHANGE_COMPANY":
      localStorage.setItem("defaultCompany", JSON.stringify(action.payload));
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case "SET_DEFAULT_COMPANY":
      if (!JSON.parse(localStorage.getItem("defaultCompany"))?.id) {
        localStorage.setItem("defaultCompany", JSON.stringify(action.payload));
      }
      return {
        ...state,
        selectedCompany: action.payload,
      };

    default:
      return state;
  }
}
