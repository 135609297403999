import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../../redux/store";
export const URL = "https://back.taakkad.com/"; // ==> live
// export const URL = "https://final.taakkad.com/"; //dev

const state = store.getState();
// const DefaultCompany = state.companyReducer?.selectedCompany;
const DefaultCompany = JSON.parse(localStorage.getItem("profile"))?.companies
  ?.data[0];
console.log({ DefaultCompany });
const instance = axios.create({
  baseURL: `${URL}api/`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("empToken")}`,
    "X-Language": localStorage.getItem("lang") ?? "en",
    "x-source": "employer",
    "X-company": DefaultCompany?.id,
    "X-device": "web",
  },
});

instance.interceptors.request.use((req) => req);

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err &&
      err.response &&
      err.response.status == 403 &&
      err.response.config.url != "/signin"
    ) {
      window.localStorage.removeItem("empToken");
      // window.location.replace("/");
    } else if (
      err.response.status == 401 &&
      err.response.config.url != "/signin"
    ) {
      window.localStorage.removeItem("empToken");
      // window.location.replace("/");
    } else {
    }
    return Promise.reject(err);
  }
);

export default instance;
